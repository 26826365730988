$width-sidebar-mobile: 300px;
$width-sidebar-tablet: 90px;
$width-sidebar-desktop: 340px;
$from-top: 80px;
$from-right: 130px;
$from-left-tablet: $from-right;
$from-left-desktop: $width-sidebar-desktop;
$content-from-top: 80px;
$mobile-height-factor: 56px;
$padding: 16px;

.default-template {
  background: var(--color-main-bg);
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  &.d-tablet {
    .content-container {
      padding-left: $width-sidebar-tablet + $padding;
    }

    .header-container,
    .footer-container {
      padding-left: $width-sidebar-tablet + $padding;
    }
  }

  &.d-desktop {
    .content-container {
      padding-left: $width-sidebar-desktop + $padding;
      padding-right: $from-right;
    }

    .header-container,
    .footer-container {
      padding-left: $width-sidebar-desktop + $padding;
      padding-right: $from-right;
    }
  }

  &.d-desktop-tablet {
    .content-container {
      padding-left: $from-left-tablet;
      padding-right: $from-right;
    }

    .header-container,
    .footer-container {
      padding-left: $from-left-tablet;
      padding-right: $from-right;
    }
  }

  .content-container {
    width: 100%;
    padding-bottom: 24px;
    padding-left: 16px;
    padding-right: 16px;
    transition: all .2s ease-out;

    &.full-page {
      padding: 0;
    }
  }

  .header-container {
    transition: all .2s ease-out;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: var(--color-main-bg);
    padding-top: $padding;
    padding-left: $padding;
    padding-right: $padding;
  }

  .footer-container {
    transition: all .2s ease-out;
    margin-top: auto;
    display: flex;
    bottom: 0;
    left: 0;
    right: 0;
    position: sticky;
    align-items: center;
    background: var(--color-main-bg);
    padding-left: $padding;
    padding-right: $padding;
    z-index: 1;

    #teleport-footer {
      width: 100%;
    }

    &.hide {
      visibility: hidden;
    }
  }
}
