.menu-item {
  +.menu-item {
    margin-top: 8px;
  }

  &--children {
    &:first-child {
      border-top: 1px solid #E9EBF3;
      padding-top: 24px;
      margin-top: 24px;
    }

    &:last-child {
      border-bottom: 1px solid #E9EBF3;
      padding-bottom: 24px;
    }
  }

  &__icon {
    flex: 1 0 24px;
    max-width: 24px;
  }

  &__title {
    flex: 0 1 auto;
  }

  &__link {
    color: #515C72;
    position: relative;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.router-link-active {
      color: #22C951;
    }

    &.is-expandable {
      &:after {
        transition: transform 0.3s linear;
        transform: rotate(-90deg);
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.6667 5.33325L7.80467 8.19525L4.94267 5.33325L4 6.27592L7.80467 10.0806L11.6093 6.27592L10.6667 5.33325Z' fill='%23191B23'/%3E%3C/svg%3E%0A");
        background-position: center center;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 12px;
      }

      &.is-expanded {
        &:after {
          transform: rotate(0deg);
        }
      }
    }

    &:hover,
    &.is-expanded {
      background-color: #F0F6F9;
    }

    .svg-icon {
      margin-right: 12px;
      height: 24px;
      width: 24px;
    }
  }

  &__subtitle {
    padding: 8px 8px 8px 44px;
    color: #515C72;
    display: block;
    margin-top: 8px;

    &.router-link-active {
      color: #22C951;
    }
  }
}
