
.data {
  width: 100%;
  background-color: #f7fafc;
  padding-top: 0;
  height: 100%;
}
.section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.section-card {
  margin: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 40px 64px;

  width: 208px;
  height: 209px;
  background: #f7fafc;

  border: 1px solid #dcdee6;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  .card-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #191b23;
    text-decoration: none;
  }
}
