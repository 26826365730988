$main_indent: 12;
$steps: (1, 2, 3, 4);
$stepsPxls: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16);
$max-values: (10, 20, 30, 40, 50, 60);

$colors: (
  'white': #FFFFFF,
  'txtFocus': #323846,
  'txtPrimary': #191B23
);

@each $name, $color in $colors {
  .u-bg--#{$name} {
    background: $color;  
  }

  .u-color--#{$name} {
    color: $color;
  }
}

@each $step in $stepsPxls { 
  .u-ma-#{$step} {
    margin: #{$step}px;
  }
  .u-mt-#{$step} {
    margin-top: #{$step}px;
  }
  .u-mr-#{$step} {
    margin-right: #{$step}px;
  }
  .u-mb-#{$step} {
    margin-bottom: #{$step}px;
  }
  .u-ml-#{$step} {
    margin-left: #{$step}px;
  }
  .u-pa-#{$step} {
    padding: #{$step}px !important;
  }
  .u-pt-#{$step} {
    padding-top: #{$step}px;
  }
  .u-pr-#{$step} {
    padding-right: #{$step}px;
  }
  .u-pb-#{$step} {
    padding-bottom: #{$step}px;
  }
  .u-pl-#{$step} {
    padding-left: #{$step}px;
  }
}

.u-ml--auto {
  margin-left: auto;
}
.u-mt--auto {
  margin-top: auto;
}

// Margin/padding
@each $step in $steps {
  .u-ma--#{$step} {
    margin: #{$main_indent * $step}px;
  }

  .u-mt--#{$step} {
    margin-top: #{$main_indent * $step}px;
  }

  .u-mr--#{$step} {
    margin-right: #{$main_indent * $step}px;
  }

  .u-mb--#{$step} {
    margin-bottom: #{$main_indent * $step}px;
  }

  .u-ml--#{$step} {
    margin-left: #{$main_indent * $step}px;
  }

  .u-pa--#{$step} {
    padding: #{$main_indent * $step}px;
  }

  .u-pt--#{$step} {
    padding-top: #{$main_indent * $step}px;
  }

  .u-pr--#{$step} {
    padding-right: #{$main_indent * $step}px;
  }

  .u-pb--#{$step} {
    padding-bottom: #{$main_indent * $step}px;
  }

  .u-pl--#{$step} {
    padding-left: #{$main_indent * $step}px;
  }
}

@each $max in $max-values {
  .u-maxh--#{$max} {
    max-height: #{$max}px;
  }

  .u-maxw--#{$max} {
    max-height: #{$max}px;
  }
}

.u-no-paddings {
  padding: 0 !important;
}

.u-align-center {
  text-align: center !important;
}

.u-align-left {
  text-align: left !important;
}

.u-align-right {
  text-align: right !important;
}

.u-uppercase {
  text-transform: uppercase;
}

.u-nowrap {
  white-space: nowrap;
}

.u-link {
  color: #22C951;
}

.u-h--100 {
  height: 100%;
}

.u-flex {
  display: flex;
}

.u-flex--col {
  flex-direction: column;
  display: flex;
}