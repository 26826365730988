.menu-button {
  margin-bottom: 15px;
  color: var(--color-text-light);
  cursor: pointer;
  transition: all .2s ease-out;
  user-select: none;
  &:hover {
    color: var(--color-text-light-hover);
  }
  &.routeIsExactActive {
    color: var(--color-text);
    &:hover {
      color: var(--color-text-hover);
    }
  }
}