
.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  .button {
    margin-top: 20px;
  }
}
