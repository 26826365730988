.profile-container {
  height: 64px;
  background: var(--color-card-bg);
  box-shadow: 3px 3px 20px 4px rgba(25, 27, 35, 0.02);
  border-radius: 12px;
  transition: width .2s ease-out;

  &.d-desktop-tablet,
  &.d-tablet {
    width: 64px;

    .profile-item {
      padding: 0 16px;
    }

    .profile-item-text {
      display: none;
    }

    .profile-item-avatar {
      display: flex;
    }
  }

  &.d-desktop {
    .profile-item-text {
      display: initial;
    }

    .profile-item-avatar {
      display: none;
    }
  }

  .profile-item {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 24px;
    justify-content: space-between;
    overflow: hidden;

    .profile-item-avatar {
      cursor: pointer;
      flex: 1 0 31px;
      max-width: 32px;
    }

    .profile-item-text {
      margin-right: auto;

      &>div:nth-of-type(1) {
        font-size: 0.875rem;
      }

      &>div:nth-of-type(2) {
        font-size: 0.75rem;
        color: var(--color-text-light);
      }
    }

    .profile-item-icon {
      height: 24px;
      width: 24px;
    }
  }
}
