
.badge {
  color: var(--green);
  display: inline-flex;
  min-width: 20px;
  min-height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid grey;
  font-size: 8px;
  line-height: 8px;
  box-sizing: border-box;
  position: absolute;
  left: 105%;
}
