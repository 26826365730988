
.main {
  display: flex;
}

.content {
  width: 100%;
  min-height: 100vh;
  background-color: #f0f3f8;
}
