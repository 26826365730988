@import '@/core/styles/mixins';

.custom-header-content {
  display: flex;
  .custom-header-content-item {
    margin-left: 8px;
    &:first-child {
      margin-left: 0;
    }
    &.desktop {
      display: none;
    }
    @include mq(tablet) {
      &.desktop {
        display: inline-block;
      }
      &.mobile {
        display: none;
      }
    }
  }
  .header-icon {
    width: 16px;
  }
  .header-action-icon {
    width: 10px;
  }
  .header-settings-icon {
    width: 16px;
  }
}
