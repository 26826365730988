.notifications {
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 999;

  .closeButton{
    .button {
      position: fixed;
      bottom: 40px;
      right: 20px;
    }
  }
}
