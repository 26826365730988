.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  border: none;
  border-radius: 4px;
  outline: none;
  font-family: 'Geometria';
  font-size: 12px;
  line-height: 16px;
  background-color: #191b23;
  color: #fff;
  cursor: pointer;

  &.primary {
    background-color: hsl(222, 17%, 24%);
  }
  &.secondary {
    background-color: #fff;
    color: #191B23;
    border: solid #E5E5E5 1px;
  }
  &.upload {
    background-color: #fff;
    color: #191B23;
    border: 1px dashed #D1D1D1;
    transform: rotate(0.13deg);
  }
}
