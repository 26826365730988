.menu-header {
  font-size: 16px;
  color: #8792ab;
  padding: 14px 8px;
  cursor: pointer;
  position: relative;
  user-select: none;
  display: inline-block;
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;

  .menu-header__title {
    display: flex;
    align-items: center;
  }

  .menu-header__icon {
    margin-right: 15px;
  }

  &.active :deep(.menu-header__icon) {
    path {
      fill: #191b23;
    }
  }
  // .menu-header__icon {
  //   fill: #000;

  //   & * {
  //     fill: #000;
  //   }
  // }

  .arrow-icon {
    width: 11px;
    height: 7px;
    transition: transform 0.2s ease;
    transform: rotateZ(-90deg);

    &.active {
      transform: rotateZ(0deg);
    }
  }

  &.with-menu {
    &.active {
      color: #191b23;
    }
  }

  &.without-menu {
    &.active,
    &.router-link-active {
      color: #191b23;
    }
  }
}
