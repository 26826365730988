@import '@/core/styles/mixins';

.custom-header-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 2px 0 9px 0;
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: rgba(0,0,0,0);
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    right: 0;
    margin: auto;

    @include mq(mobile) {
      bottom: 0;
    }
  }

  &.d-mobile {
    flex-wrap: wrap;

    .header-icon-btn {
      cursor: pointer;
      order: 0;
    }

    .header-breadcrumb {
      margin-top: 8px;
      padding-top: 10px;
      font-size: 12px;
      line-height: 16px;
      border-top: 1px solid #e9ebf3;
      order: 2;
      flex-basis: 100%;
      z-index: 15;
    }

    .header-filters {
      order: 1;
    }
  }

  .header-filters-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .header-icon {
    width: 16px;
    height: 16px;
    color: var(--color-text-light);
  }
}
