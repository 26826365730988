$width-sidebar-mobile: 300px;
$width-sidebar-tablet: 96px;
$width-sidebar-desktop: 295px;

.sidebar-wrapper {
  background: transparent;

  &.d-desktop-tablet :deep(),
  &.d-tablet :deep() {
    .sidebar {
      width: $width-sidebar-tablet;
      left: 0;

      .menu-header__title-main,
      .menu-item__subtitle,
      .icon-logo,
      .menu-item__title,
      .menu-item__link:after {
        display: none !important;
      }

      .menu-item__icon {
        margin-right: 0;
      }
    }
  }

  &.d-desktop :deep() {
    .sidebar {
      width: $width-sidebar-desktop;
      left: 0;
      height: 100vh;

      .menu-item__subtitle {
        display: block;
      }

      .icon-logo,
      .menu-item__title,
      .menu-item__link:after {
        display: initial;
      }

      .menu-item__icon {
        margin-right: 12px;
      }
    }
  }

  &.d-force-collapsed {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;

    :deep() .sidebar {
      width: $width-sidebar-desktop;
      left: 0;

      .menu-item__icon,
      .icon-menu {
        margin-left: 0;
      }

      .icon-logo,
      .menu-item__title,
      .menu-item__link:after {
        display: initial;
      }

      .menu-item__subtitle {
        display: block;
      }

      .menu-item__icon {
        margin-right: 12px;
      }

      .profile-container {
        width: initial;

        .profile-item-text {
          display: initial;
        }

        .profile-item-avatar {
          display: none;
        }
      }
    }
  }

  .sidebar {
    padding: 30px 16px 32px;
    left: -$width-sidebar-mobile;
    transition: left 0.3s linear;
    position: fixed;
    top: 0;
    z-index: 2;
    width: $width-sidebar-mobile;
    background: var(--color-main-bg);
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    overflow-y: auto;
    user-select: none;

    .sidebar-logo {
      height: 32px;
      padding-left: 8px;
      display: flex;
      align-items: center;

      .icon-menu {
        flex: 1 0 24px;
        max-width: 24px;
        cursor: pointer;
        width: 24px;
        height: 24px;
      }

      .icon-logo {
        width: 120px;
        margin-left: 12px;
        cursor: pointer;
      }
    }

    .sidebar-menu {
      display: flex;
      // flex-shrink: 0;
      overflow-y: auto;
      flex-direction: column;
      padding-top: 30px;
      margin-bottom: auto;
    }
  }

  .sidebar-footer {
    margin-top: 16px;
  }
}

:deep(.sidebar-link:not(.menu-button)) {
  color: var(--color-text-light);
  cursor: pointer;
  transition: all 0.3s linear;
  user-select: none;

  &:hover {
    color: var(--color-text-light-hover);
  }

  & + .menu-subheader-wrapper,
  & + .menu-button-wrapper {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }

  &.router-link-active {
    color: var(--color-text);

    &:hover {
      color: var(--color-text-hover);
    }

    & + .menu-subheader-wrapper,
    & + .menu-button-wrapper {
      opacity: 1;
      visibility: visible;
      position: initial;
    }
  }
}
