.search {
  position: relative;
  cursor: text;
  display: flex;
  flex-direction: row;
  align-items: center;

  border: 1.08019px solid #191b23;
  border-radius: 8px;
  transition: border ease-in-out 0.2s;
  color: #191b23;

  border: 1px solid #dcdee6;
  box-sizing: border-box;
  border-radius: 4px;

  &.expanded {
    background: #ffffff;
  }
}

.search__field {
  outline: none;
  width: calc(100% - 44px);

  border: none;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  caret-color: #57d535;

  &::placeholder {
    color: #191b23;
  }

  &:focus::placeholder {
    color: transparent;
  }
}

.search__btn {
  height: 100%;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: relative;
  top: 2px;
  cursor: pointer;
}

.search__icon {
  height: 15px;
  width: 15px;
  z-index: 20;

  path {
    fill: #191b23;
    transition: fill ease-in-out 0.2s;
  }
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 40px;
  }
}
