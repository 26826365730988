.home {
  min-height: 100vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7fafc;
}

.patchnote-wrapper {
  width: 100%
}

.show-history-btn {
  color: var(--green);
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  text-decoration: underline;
  padding: 0 16px;
}
