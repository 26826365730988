.chat-message-item {
  background-color: #fff;
  padding: 20px;
  overflow: hidden;
  width: 360px;
  opacity: 0.9;
  box-shadow: 0 5px 10px rgba(black, 0.15);
  border-radius: 12px;
  transition: all 0.2s ease;

  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    opacity: 1;
    box-shadow: 0 5px 10px rgba(black, 0.1);
  }

  .item__header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
  }

  .item__body {
    font-size: 14px;
    max-height: 50px;
    overflow: hidden;
    white-space: pre-wrap;
    overflow-wrap: normal;
  }

  .item__header-close {
    margin-left: 20px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    cursor: pointer;
  }

  .item__header-title {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
  }

  .item__footer {
    margin-top: 20px;
  }
}
