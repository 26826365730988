.no-content {
  display: flex;
  flex-flow: column;
  align-items: center;

  .no-content__icon {
    width: 128px;
    height: 128px;
  }

  .no-content__text {
    text-align: center;
    margin-top: 10px;
    font-size: 24px;
    font-weight: 500;
    color: #76869e;
  }
}
