@import '@/core/styles/mixins';

.notifications-card-wrapper {
  padding: 24px;
  color: var(--color-text);
  border: 1px solid #E9EBF3;
  border-radius: 4px;

  h2 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .date {
      color: #8792AB;
      font-size: 14px;
    }

    .title {
      margin: 0;
    }
  }

  .notifications-card-list {
    font-size: 14px;
    margin-bottom: 10px;
    color: #515C72;
    font-weight: 400;
  }

  .notifications-card-showmore {
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
  }

  .notifications-card-content {
    font-size: 14px;
    color: #515C72;
    padding-bottom: 10px;
  }
}

.main-title {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 16px;
  color: var(--color-text);
}

.overline {
  border: 0;
  border-top: 1px solid #E9EBF3;
  padding: 24px 0;
}

.show-history-btn {
  color: var(--green);
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  text-decoration: underline;
  padding: 0 16px;
}

.text-link {
  color: var(--green);
  cursor: pointer;
  text-decoration: underline;
}

.patch-list {
  display: list-item;
  list-style-position: inside;
  line-height: 20px;
  font-weight: 400;
  color: #515c72;
  font-size: 14px;
}

.patch-item {
  padding-top: 10px;
}

.ordered {
  list-style-type: decimal;
}

.bullet {
  list-style-type: disc;
}

.patchnote-wrapper {
  padding-bottom: 10px;
}
